import React from "react"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"

// Data

class PrivacyPolicy extends React.Component {
  render() {
    const breadcrumbs = [
      {
        label: "Privacy Policy",
        path: "/privacy",
        active: true,
      },
    ]

    return (
      <Layout>
        <SEO
          title="Privacy Policy"
          description="Our privacy policy that outlines how we use and safeguard any information you provide us."
        />

        <Breadcrumbs items={breadcrumbs} absolute={true} />

        <div className="uk-section">
          <div className="uk-container uk-container-small">
            <h1>Privacy Policy</h1>
            <p>
              Reece Pty Ltd will on occasions collect personal information from
              individuals. The Privacy Act does not apply to companies. The
              privacy of your personal information is afforded the highest level
              of importance by Reece Pty Ltd. We are bound by the Privacy Act
              1988 (Cth) regarding the manner in which we handle your personal
              information and how we respond to your requests to access and
              correct it. This document sets out our information handling
              procedures and the rights and obligations that both you and we
              have in relation to your personal information.
            </p>
            <p>
              We note that if Reece Pty Ltd is not able to collect your personal
              information it may not be able to provide you with a credit
              facility if you are eligible and to provide you with an
              appropriate level of service.
            </p>
            <h2>How we collect your information</h2>
            <p>
              We primarily collect information about you through correspondence
              we engage in with you, for example, through application forms,
              telephone conversations and emails. We also collect information
              from sources that are publicly available such as websites,
              journals and phone books. Sometimes, we collect information about
              our customers from our sales agents and distributors who have
              direct contact with you on our behalf. We also make use of cookies
              on our website to collect personal information about visitors to
              the site including browsing habits.
            </p>
            <h2>Kinds of information that we hold about you</h2>
            <p>
              We only hold information about you if it is relevant to providing
              the services and products that we provide and for the provision of
              information to manufacturers in the event of a recall of products.
              Such information generally includes your name, contact details,
              type of business, payment details, account number, records of
              correspondence and billing documents. If you are one of our
              suppliers, we may collect other information about you that we feel
              is necessary, such as the nature of the products or services that
              you supply, quotes that you provide and your direct credit
              details.
            </p>
            <h2>How we hold your information</h2>
            <p>
              Depending on the circumstances, we may hold your information in
              either hardcopy or electronic form, or both.
            </p>
            <h2>How we use your information</h2>
            <p>We use your personal information:</p>
            <ul>
              <li>To provide you with our products and services;</li>
              <li>To administer our customer relationship with you;</li>
              <li>
                For the provision of information to manufacturers in the event
                of a recall of products and to warranty service providers;
              </li>
              <li>
                For internal purposes such as procedural assessments, risk
                management, product and service reviews, staff training,
                accounting and billing and;
              </li>
              <li>
                To identify, and inform you of products and services that may be
                of interest to you including direct marketing to you
              </li>
            </ul>
            <p>
              If you are one of our suppliers, we may use your information to
              facilitate our business relationship with you, for example, to
              assess goods or services that you supply or to review a commercial
              proposal that you have put to us.
            </p>
            <h2>How we use or disclose your information</h2>
            <p>
              Generally, we will only use or disclose your personal information
              for a purpose that is related to the product or service that we
              are providing to you. This may include disclosures to
              organisations that provide us with professional advice, such as
              solicitors, accountants and business advisors, and to contractors
              to whom we out-source certain functions, such as mailing houses,
              electronic network administrators and debt collection agencies.
              However, where possible, we take contractual measures, and in all
              other circumstances, take all reasonable measures, with our
              contractors to ensure that they comply with the privacy standards
              set out in the Privacy Act 1988 (Cth). We may also disclose
              information where it is expressly permitted under the Privacy Act
              1988 (Cth), for example, where it is with your consent or where we
              are legally required to do so, such as under a court order or
              taxation laws.
            </p>
            <h2>How we secure your information</h2>
            <p>
              We hold all hardcopy and electronic records of personal
              information in a secure manner to ensure that they are protected
              from unauthorised access, modification or disclosure. Our staff
              follow strict information handling procedures and we only permit
              those staff whose tasks require use of your information to access
              it. We delete your personal information once it is no longer
              needed or required to be kept by law by deleting electronic
              records.
            </p>
            <h2>Your right to lodge a complaint</h2>
            <p>
              If you are not satisfied with how we have handled your personal
              information, or want to complain that there has been a breach of
              an Australian Privacy Principle or any APP code which applies you
              are entitled to lodge a complaint with the Federal Privacy
              Commissioner. Information regarding how to lodge a complaint is
              available from the Commissioner’s website at{" "}
              <a
                href="https://www.oaic.gov.au/"
                target="_blank"
                rel="noreferrer"
              >
                www.oaic.gov.au
              </a>{" "}
              or by ringing the Commissioner’s information line on{" "}
              <a href="tel:1300 363 992">1300 363 992</a>. However, before
              investigating a complaint, the Commissioner is legally required to
              be satisfied that you have first expressed your concern to us to
              afford us an opportunity to resolve the complaint directly, unless
              it is inappropriate for you to do so. You may lodge a complaint
              with our Privacy Officer either by mail at Reece Pty Ltd Private
              Bag 109 Burwood Vic 3125, or by email to{" "}
              <a href="mailto:privacy.officer@reece.com.au">
                privacy.officer@reece.com.au
              </a>
              . We will inform you of who will handle your complaint and you may
              contact our Privacy Officer to enquire about its stage of progress
              at any time. We will go to great lengths to ensure that your
              complaint is resolved to your satisfaction.
            </p>
            <h2>Your right of access</h2>
            <p>
              You may request access to your personal information at any time by
              sending a written request to our Reece Pty Ltd Privacy Officer by
              mail at Private Bag 109 Burwood Vic 3125, or by email to{" "}
              <a href="mailto:privacy.officer@reece.com.au">
                privacy.officer@reece.com.au
              </a>
              . In your request, please state how you would like to obtain
              access. For example, you may like to inspect our records at our
              premises or you may prefer to be sent a photocopy or an electronic
              version of your information. You do not need to provide a reason
              for your request. Once our Privacy Officer has verified your
              identity, your request will be forwarded to our information
              systems manager who will arrange for access to be provided to you
              in an appropriate manner within 14 days. We may charge a small fee
              for providing access if it requires a significant amount of time
              to locate your information or to collate or present it in an
              appropriate form. If your account is held in conjunction with
              another individual, we will provide each person named on the
              account with access to information held about each account holder.
              Our Privacy Officer will follow up your request to ensure that the
              level of access with which you have been provided is to your
              satisfaction. In rare circumstances, and only where it is
              permitted under the Privacy Act 1988 (Cth), we may not be able to
              provide you with access to your information; for example, where it
              will have an unreasonable impact upon the privacy of others, where
              it relates to legal proceedings between us through which the
              information would not otherwise be available, where it would be
              prejudicial to negotiations we are holding with you, where we are
              required by law to withhold the information or where it would
              reveal information relating to our commercially sensitive decision
              making processes. If we are unable to provide you with access, we
              will state why this is so and consider whether the use of an
              intermediary would be appropriate to provide you with an
              explanation of your personal information.
            </p>
            <h2>You may correct your information</h2>
            <p>
              If your personal information is inaccurate, out-of-date,
              incomplete, irrelevant or misleading, you may request we correct
              the information and we will consider your request. In the unlikely
              event that we disagree about the accuracy of the information and
              are unable to change it, you may provide us with a statement
              indicating that you dispute its accuracy and we will associate the
              statement with your information in such a manner that it will be
              brought to the attention of each person who uses the information.
            </p>
            <h2>Direct marketing</h2>
            <p>
              We would like to directly market to you about goods and services
              that we believe may be of interest to you, however, we understand
              that you may not wish to receive such material. If so, please
              inform our Privacy Officer of this in writing by mail at Reece Pty
              Ltd Private Bag 109 Burwood Vic 3125, or by email to{" "}
              <a href="mailto:privacy.officer@reece.com.au">
                privacy.officer@reece.com.au
              </a>{" "}
              and we’ll ensure that your name is removed from our direct
              marketing list.
            </p>
            <h2>Transfer of your information overseas</h2>
            <p>
              We will only transfer your personal information overseas if the
              transfer is in accordance with the Privacy Act.
            </p>
            <h2>We may need to change our privacy policy from time to time</h2>
            <p>
              Due to changing business circumstances, we may need to change our
              privacy policy from time to time. If we do, we will endeavour to
              ensure that you’re overall level of privacy protection is not
              diminished and will publish the changes on our website. Any
              actions that we have take before the change will continue to be
              regulated by the privacy policy that existed before the changes
              were made.
            </p>
            <h2>Further information</h2>
            <p>
              We are happy to provide you with further information regarding
              your privacy. If you have any queries or requests in this respect,
              please contact our Privacy Officer either by mail at Reece Pty Ltd
              Private Bag 109 Burwood Vic 3125 or by email to{" "}
              <a href="mailto:privacy.officer@reece.com.au">
                privacy.officer@reece.com.au
              </a>
              .
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PrivacyPolicy
